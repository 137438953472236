<template>
  <div class="gallery__wrapper container">
    <h2 class="title">Галерея</h2>
    <div class="gallery__content">
      <Progress v-show="progress" />
      <div class="gallery__items">
        <div class="gallery__item" v-for="item in gallery_list" :key="item.gallery_id">
          <img :src="'/img/gallery/' + item.img" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Progress from "@/components/v-progress.vue";
export default {
  components: {
    Progress,
  },
  data() {
    return {
      gallery_list: [],
      progress: true,
    };
  },
  methods: {
    func_get_gallery() {
      window
        .sendRequest({
          num: 14,
        })
        .then((result) => {
          if (result) {
            this.gallery_list = result;
            this.progress = false;
          }
        });
    },
  },
  mounted() {
    this.func_get_gallery();
  },
};
</script>

<style>
.gallery__wrapper {
  margin-top: 100px;
}
.gallery__content {
  position: relative;
  min-height: 150px;
}
.gallery__items {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.gallery__items .gallery__item {
  flex-basis: 32%;
  height: 250px;
  display: flex;
  cursor: pointer;
  margin-bottom: 15px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 5px #c2c2c2;
  border-radius: 1px solid #c2c2c2;
}
.gallery__items .gallery__item img {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;
  object-position: center;
}
</style>
