<template>
  <div class="contactы__wrapper container">
    <h2 class="title">Контакты</h2>
    <div class="contact__content">
      <div class="contact__text">
        <div class="text__adress">
          <p class="adress">
            Московская область, г. Мытищи ул. Коммунистическая 25г. Корпус 5
          </p>
          <p class="rezim">Пн-Вс: 8:00 - 20:00</p>
        </div>
        <a href="tel:89850686067"
          ><i class="fa fa-phone" aria-hidden="true"></i>+7 (985) 068-60-67</a
        >
        <a href="tel:89259622413"
          ><i class="fa fa-phone" aria-hidden="true"></i>+7 (925) 962-24-13</a
        >

        <a href="mailto:info@брус-плюс.рф"
          ><i class="fa fa-envelope-o" aria-hidden="true"></i>info@брус-плюс.рф</a
        >
      </div>
      <div class="contact__map" id="map"></div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    let map = document.createElement("script");
    map.setAttribute("type", "text/javascript");
    map.setAttribute("charset", "utf-8");
    map.setAttribute("async", "true");
    map.setAttribute(
      "src",
      "https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3Ad63d1cc74acd8dbb29eda0b09ab7d84b487cc77f7eccfafe2ec80342e48da51d&amp;width=100%25&amp;height=456&amp;lang=ru_RU&amp;scroll=true"
    );
    document.querySelector("#map").appendChild(map);
  },
};
</script>

<style>
.text__adress p {
  margin: 0;
}
.adress {
  font-size: 20px;
  margin-top: 5px;
}
.text__adress {
  margin-bottom: 10px;
}
.contact__content {
  display: flex;
}
.contact__content .contact__text {
  flex-basis: 40%;
}
.contact__content .contact__map {
  flex-basis: 60%;
}
.rezim {
  font-size: 20px;
  font-weight: 700;
}
.contactы__wrapper {
  margin-top: 100px;
}
.contact__text {
  font-size: 13px;
}
.contact__text a {
  display: flex;
  align-items: center;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 700;
  color: green;
  text-decoration: none;
}
.contact__text a i {
  margin-right: 10px;
  text-decoration: none;
  font-size: 20px;
}
.contact__text p a {
  font-size: 14px;
  margin-top: 0;
  display: inline;
}
.contact__text p img {
  width: 25px;
  margin-right: 5px;
}
</style>
