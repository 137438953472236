<template>
  <div class="catalog__wrapper container">
    <h2 class="title">Каталог</h2>
    <div class="catalog__content">
      <Progress v-show="progress" />
      <Search />
      <h4>{{ category_name }}</h4>
      <div class="category__items" v-if="category">
        <div
          class="category__item"
          v-for="item in category_list"
          :key="item.category_id"
          @click="func_get_products(item.category_id)"
        >
          <div class="item">
            <div class="category__img">
              <img
                :src="
                  item.img
                    ? '/img/category/' + item.img
                    : require('@/assets/0259a878942a099e46261bbd49e85608.jpg')
                "
                :alt="item.category_name"
              />
            </div>
            <div class="category__name">
              {{ item.category_name }}
            </div>
            <div class="category__price">
              <p>
                от
                {{
                  item.min_price
                    ? Number(item.min_price).toFixed(2)
                    : Number(0).toFixed(2)
                }}
                руб.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="products__list" v-else>
        <div class="sort">
          <label>Сортировать по</label>
          <select v-model="sort">
            <option value="1">возрастанию цены</option>
            <option value="2">убыванию цены</option>
          </select>
        </div>

        <div class="products__items">
          <Product v-for="item in products_list" :key="item.product_id" :product="item" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Progress from "@/components/v-progress.vue";
import Product from "@/components/v-product.vue";
import Search from "@/components/v-search.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Progress,
    Product,
    Search,
  },
  props: {
    category_id: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      categoryId: null,
      progress: true,
      category_list: [],
      category_name: null,
      category: true,
      products_list: [],
      sort_main: 1,
    };
  },
  methods: {
    func_sort() {
      if (Number(this.sort_main) === 1) {
        this.products_list.sort((a, b) => {
          if (Number(a.price) > Number(b.price)) return 1;
          else return -1;
        });
      } else {
        this.products_list.sort((a, b) => {
          if (Number(a.price) < Number(b.price)) return 1;
          else return -1;
        });
      }
    },
    func_get_products(category_id) {
      if (category_id > 0) {
        window
          .sendRequest({
            num: 9,
            param: {
              category_id,
            },
          })
          .then((result) => {
            if (result) {
              if (result.category_list) {
                this.category = true;
                this.category_list = result.category_list;
                this.category_name = result.category_name;
                this.progress = false;
              }
              if (result.products_list) {
                this.category = false;
                this.products_list = result.products_list.sort((a, b) => {
                  if (Number(a.price) > Number(b.price)) return 1;
                  else return -1;
                });
                this.category_name = result.category_name;
                this.progress = false;
              }
            }
          });
      } else {
        window
          .sendRequest({
            num: 2,
          })
          .then((result) => {
            if (result && result.category_list) {
              this.category = true;
              this.category_list = result.category_list;
              this.progress = false;
            }
          });
      }
    },
  },
  watch: {
    CATEGORY_STATE() {
      this.categoryId = this.CATEGORY_STATE;
      this.func_get_products(this.categoryId);
    },
  },
  computed: {
    ...mapGetters(["CATEGORY_STATE"]),
    sort: {
      get() {
        return this.sort_main;
      },
      set(value) {
        this.sort_main = value;
        this.func_sort();
      },
    },
  },
  mounted() {
    this.categoryId = this.category_id;
    this.func_get_products(this.categoryId);
  },
};
</script>

<style>
.catalog__wrapper {
  margin-top: 100px;
}
.products__list select {
  margin: 10px 5px;
  padding: 5px 10px;
  border: 1px solid green;
  border-radius: 5px;
}
.products__list select:focus {
  outline: none;
  border: 1px solid green;
}
.products__list select option {
  padding: 5px;
}
.catalog__content {
  position: relative;
  min-height: 250px;
}

category__items {
  display: flex;
  flex-wrap: wrap;
  padding-top: 5px;
}
.category__item {
  flex-basis: 25%;
  padding: 10px;
}
.category__item .item {
  cursor: pointer;
  border: 1px solid #e2e2e2;
  position: relative;
  transition: 0.3s;
  height: 100%;
  border-radius: 5px;
  box-shadow: 0 0 4px #c2c2c2;
}
.category__item .item:hover {
  transform: scale(0.97);
}
.category__img {
  width: 100%;
  height: 200px;
  display: flex;
  position: relative;
}
.category__img:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.3s;
}
.category__img img {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;
  object-position: center;
}
.category__price {
  background: #fff;
  color: #000;
  padding: 5px;
}
.category__name {
  width: 100%;
  background: #fff;
  color: #333;
  padding: 5px;
  font-size: 17px;
  font-weight: 700;
  text-align: center;
  border-bottom: 1px solid #e2e2e2;
}
.category__price p {
  margin: 0;
  margin-top: 5px;
  text-align: center;
}
.catalog__content h4 {
  margin: 10px 0;
  border-bottom: 1px solid #c2c2c2;
}
.products__items {
  display: flex;
  flex-wrap: wrap;
}
</style>
