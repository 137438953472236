<template>
  <div class="menu__container">
    <div class="menu__head">
      <div class="city">
        <i class="fa fa-map-marker" aria-hidden="true"></i>
        Мытищи ул. Коммунистическая 25г. Корпус 5
      </div>
      <div class="phone">
        <a href=""><i class="fa fa-phone" aria-hidden="true"></i>+7 (985) 068-60-67</a>
      </div>
    </div>
    <div class="menu__wrapper">
      <input type="checkbox" name="toggle" id="menu" class="toggleMenu" />
      <label for="menu" class="toggleMenu"
        >Меню
        <img :src="require('@/assets/hamburger_button_menu_icon_155296.png')" alt=""
      /></label>
      <div class="menu__list">
        <h2 class="logo" @click="func_link_to()">Брус-Плюс</h2>
        <ul class="menu">
          <li @click="func_link(item.component)" v-for="item in menu" :key="item.menu_id">
            {{ item.title }}
          </li>
          <button
            class="cart__btn"
            @click="CART_STATE.length > 0 ? $router.push('/order') : null"
          >
            <div class="count__cart" v-if="CART_STATE.length > 0">
              {{ CART_STATE.length }}
            </div>
            <i class="fa fa-shopping-cart" aria-hidden="true"></i>
          </button>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      menu: [
        { menu_id: 1, title: "О компании", component: "/about" },
        { menu_id: 2, title: "Каталог", component: "/catalog" },
        { menu_id: 3, title: "Отзывы", component: "/answers" },
        { menu_id: 7, title: "Галерея", component: "/gallery" },
        { menu_id: 4, title: "Оплата", component: "/payment" },
        { menu_id: 5, title: "Доставка", component: "/delivery" },
        { menu_id: 6, title: "Контакты", component: "/contact" },
      ],
    };
  },
  methods: {
    func_link(component) {
      if (this.$router.history.current.path !== component) {
        this.$router.push(component);
      }
      if (component === "/catalog") {
        this.$route.params.category_id = -1;
        this.$store.commit("CHANGE_CATEGORY", -1);
      }
    },
    func_link_to() {
      if (window.location.pathname !== "/") this.$router.push("/");
    },
  },
  computed: {
    ...mapGetters(["CART_STATE"]),
  },
};
</script>

<style>
.menu__list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0 auto;
}
.menu__list .logo {
  cursor: pointer;
}
.menu__container {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  background: #fff;
  box-shadow: 0 0 15px #bdbdbd;
}
.menu__head {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  font-size: 12px;
  border-bottom: 1px solid #e2e2e2;
}
.menu__head i {
  font-size: 18px;
  margin-right: 5px;
}
.menu__head .city i {
  color: red;
}
.menu__head .phone i {
  color: blue;
}
.menu__head .phone a {
  text-decoration: none;
  display: flex;
  align-items: center;
}
.menu__head .city {
  display: flex;
  align-items: center;
}
.menu__wrapper {
  background: #fff;
  padding-bottom: 10px;
}
.menu {
  display: flex;
  list-style-type: none;
  justify-content: flex-end;
  align-items: center;
  padding-top: 10px;
}
.menu li {
  text-align: center;
  padding: 5px 12px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  transition: 0.5s;
  margin: 0px 5px;
  background: #fff;
  color: #333;
}
.menu li:last-child {
  border-right: none;
}
.menu li:hover {
  color: #008000;
  border-bottom: 2px solid #008000;
}
.active_menu {
  color: #008000;
  border-bottom: 2px solid #008000;
}
.menu__wrapper [type="checkbox"] {
  display: none;
}
.menu__wrapper label.toggleMenu {
  background: green;
  color: #fff;
  display: none;
  padding: 15px 40px;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  justify-content: space-between;
  align-items: center;
}
.menu__wrapper label.toggleMenu img {
  width: 30px;
  height: 30px;
}
.cart__btn {
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  position: relative;
}
.cart__btn i {
  font-size: 25px;
  color: green;
}
.count__cart {
  position: absolute;
  top: -50%;
  right: -50%;
  background: red;
  color: #fff;
  padding: 3px;
  font-size: 8px;
}
</style>
