<template>
  <div class="search__wrapper">
    <div class="search__content">
      <form @submit.prevent="func_search">
        <input type="text" v-model="search" placeholder="Найти..." required />
        <button>Поиск</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
    };
  },
  methods: {
    func_search() {
      if (this.search) {
        this.$store.commit("CHANGE_SEARCH", this.search);
        if (this.$router.history.current.path !== "/search") {
          this.$router.push("/search");
        }
        if (this.search) {
          this.$route.params.search = this.search;
        }
      }
    },
  },
};
</script>

<style>
.search__wrapper {
  margin: 20px 0;
}
.search__content form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.search__content input {
  padding: 10px;
  flex-basis: 88%;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
}
.search__content input:focus {
  border-color: green;
  outline: none;
}
.search__content button {
  padding: 8px;
  border: none;
  background: green;
  color: #fff;
  cursor: pointer;
  flex-basis: 10%;
  transition: 0.5s;
  border-radius: 5px;
}
.search__content button:hover {
  opacity: 0.9;
}
</style>
