<template>
  <div class="admin__wrapper container">
    <div class="admin__title">
      <h2>Панель администратора</h2>
    </div>
    <div class="admin__content" v-if="ADMIN_AUTH_STATE">
      <AdminMenu @select_menu="func_get_selected_menu" />
      <div class="admin__module">
        <AdminCategory v-if="select_menu === 1" />
        <AdminProducts v-if="select_menu === 2" />
        <AdminInfo v-if="select_menu === 3" />
        <AdminAnswer v-if="select_menu === 4" />
        <AdminCall v-if="select_menu === 5" />
        <AdminPartners v-if="select_menu === 6" />
        <AdminOrder v-if="select_menu === 7" />
        <AdminGallery v-if="select_menu === 8" />
        <AdminDelivery v-if="select_menu === 9" />
      </div>
    </div>
    <div class="not__auth" v-else>
      <form @submit.prevent="func_auth">
        <input type="text" v-model="login" placeholder="Логин" required />
        <input type="password" v-model="password" placeholder="Пароль" required />
        <button>Войти</button>
        <p v-show="error">{{ error }}</p>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AdminMenu from "@/components/admin/v-admin-menu";
import AdminAnswer from "@/components/admin/v-admin-answer";
import AdminCall from "@/components/admin/v-admin-call";
import AdminPartners from "@/components/admin/v-admin-partners";
import AdminInfo from "@/components/admin/v-admin-info";
import AdminProducts from "@/components/admin/v-admin-products";
import AdminCategory from "@/components/admin/v-admin-category";
import AdminOrder from "@/components/admin/v-admin-order";
import AdminGallery from "@/components/admin/v-admin-gallery";
import AdminDelivery from "@/components/admin/v-admin-delivery";
export default {
  components: {
    AdminMenu,
    AdminCategory,
    AdminCall,
    AdminAnswer,
    AdminPartners,
    AdminInfo,
    AdminProducts,
    AdminOrder,
    AdminGallery,
    AdminDelivery,
  },
  data() {
    return {
      select_menu: 0,
      login: "",
      password: "",
      error: "",
    };
  },
  methods: {
    func_get_selected_menu(menu_id) {
      if (menu_id) this.select_menu = menu_id;
    },
    func_auth() {
      if (this.login && this.password) {
        this.error = "";
        window
          .sendRequestAdmin({
            num: 22,
            param: {
              login: this.login,
              password: this.password,
            },
          })
          .then((result) => {
            if (result) {
              if (result.status) {
                this.$store.commit("CHANGE_ADMIN_AUTH", true);
              } else {
                this.$store.commit("CHANGE_ADMIN_AUTH", false);
                this.error = "Не верные логин или пароль";
              }
            }
          });
      }
    },
  },
  computed: {
    ...mapGetters(["ADMIN_AUTH_STATE"]),
  },
};
</script>

<style>
.admin__wrapper {
  margin-top: 100px;
}
.admin__content,
.admin__module {
  margin-top: 20px;
}
.admin__wrapper form {
}
.admin__wrapper input,
.admin__wrapper textarea {
  padding: 8px;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #c2c2c2;
}
.admin__wrapper input[type="file"] {
  border: none;
}
.admin__wrapper button {
  padding: 10px 20px;
  border: none;
  background: #5f4330;
  color: #fff;
  cursor: pointer;
  transition: 0.5s;
}
.admin__wrapper button:hover {
  background: #362a21;
}
.admin__wrapper table {
  margin-top: 10px;
  word-break: normal;
  border: 1px solid #c2c2c2;
}
.admin__wrapper table td,
.admin__wrapper table th {
  padding: 5px;
  font-size: 12px;
}
.admin__wrapper table button {
  width: 150px;
}

.admin__wrapper table tbody tr {
  transition: 0.5s;
  cursor: pointer;
}
.admin__wrapper table tbody tr:hover {
  background: #e2e2e2;
}
</style>
