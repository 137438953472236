<template>
  <div class="footer__wrapper">
    <div class="footer__content">
      <div class="footer__info">
        <p>
          © 2006–{{ new Date().getFullYear() }} "Брус-Плюс" Все права защищены.
          Интернет-магазин пиломатериалов от производителя.
        </p>
        <p>Московская область, г. Мытищи ул. Коммунистическая 25г. Корпус 5</p>
      </div>
    </div>
    <div class="footer__soc">
      <a href="#"><img :src="require('@/assets/soc/vk.png')" alt="" /></a>
      <a href="#"><img :src="require('@/assets/soc/photo.png')" alt="" /></a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.footer__wrapper {
  display: flex;
  margin-top: 15px;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  font-size: 14px;
}
.footer__content {
  width: 80%;
  display: flex;
}
.footer__info {
  flex-basis: 50%;
}
.footer__info p {
  margin-top: 10px;
}
.footer__info p:last-child {
  font-weight: 700;
  font-size: 14px;
}
.footer__soc {
  display: flex;
}
.footer__soc img {
  width: 50px;
  margin-right: 20px;
}
</style>
