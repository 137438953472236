<template>
  <div class="search__page__wrapper container">
    <div class="search__title">
      <h3>Поиск '{{ search }}'</h3>
    </div>
    <Search />
    <div class="search__result">
      <Progress v-show="progress" />
      <div class="products__list" v-if="products_list && products_list.length > 0">
        <label>Сортировать по</label>
        <select v-model="sort">
          <option value="1">возрастанию цены</option>
          <option value="2">убыванию цены</option>
        </select>
        <div class="products__items">
          <Product v-for="item in products_list" :key="item.product_id" :product="item" />
        </div>
      </div>
      <div class="err" v-else>
        {{ err }}
      </div>
    </div>
  </div>
</template>

<script>
import Product from "@/components/v-product.vue";
import Search from "@/components/v-search.vue";
import Progress from "@/components/v-progress.vue";
import { mapGetters } from "vuex";
export default {
  props: {
    search: String,
  },
  components: {
    Product,
    Search,
    Progress,
  },
  data() {
    return {
      products_list: [],
      sort_main: 1,
      progress: true,
      err: "",
    };
  },
  methods: {
    func_sort() {
      if (Number(this.sort_main) === 1) {
        this.products_list.sort((a, b) => {
          if (Number(a.price) > Number(b.price)) return 1;
          else return -1;
        });
      } else {
        this.products_list.sort((a, b) => {
          if (Number(a.price) < Number(b.price)) return 1;
          else return -1;
        });
      }
    },
    func_get_products_search() {
      this.progress = true;
      this.products_list.length = 0;
      window
        .sendRequest({
          num: 10,
          param: {
            search: this.search,
          },
        })
        .then((result) => {
          if (result && result.products_list) {
            this.products_list = result.products_list.sort((a, b) => {
              if (Number(a.price) > Number(b.price)) return 1;
              else return -1;
            });
          } else {
            this.err = "Не найдено";
          }
          this.progress = false;
        });
    },
  },
  watch: {
    SEARCH_STATE() {
      this.search = this.SEARCH_STATE;
      this.func_get_products_search();
    },
  },
  mounted() {
    if (this.search) {
      this.func_get_products_search();
    }
  },
  computed: {
    ...mapGetters(["SEARCH_STATE"]),
    sort: {
      get() {
        return this.sort_main;
      },
      set(value) {
        this.sort_main = value;
        this.func_sort();
      },
    },
  },
};
</script>

<style>
.search__page__wrapper {
  margin-top: 100px;
}
.search__result {
  min-height: 300px;
  position: relative;
}
</style>
