<template>
  <div class="header__content">
    <div class="header__wrapper">
      <div class="header__item">
        <div class="phone">
          <h1>Брус-Плюс</h1>
          <p>
            Лесопромышленная компания "Брус-Плюс" начала свою деятельность в 1996 году. За
            это время компания вышла на одно из первых мест по производству и продажам
            лесопромышленной продукции. Предприятие специализируется на производстве
            изделий из лиственницы, сосны, кедра.
          </p>
          <p>
            Благодаря приобретению нового и модернизации существующего
            деревообрабатывающего оборудования, список производимых изделий постоянно
            расширяется.
          </p>
        </div>
      </div>
      <div class="header__item header__item__form">
        <form @submit.prevent="func_submit" class="callback">
          <div class="form__header">
            <h4>Оставить заявку</h4>
            <p>Мы вам перезвоним в течение 15 минут</p>
          </div>
          <div class="form__group">
            <input type="text" placeholder="Имя" v-model="user_name" required />
          </div>
          <div class="form__group">
            <input type="tel" placeholder="Телефон" v-model="user_phone" required />
          </div>
          <button>Отправить</button>
          <div class="err" v-show="err">{{ err }}</div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return { err: "", user_name: "", user_phone: "" };
  },
  methods: {
    func_show_modal() {
      this.$store.commit("CHANGE_MODAL", true);
    },
    func_submit() {
      if (this.user_name && this.user_phone) {
        window
          .sendRequest({
            num: 5,
            param: {
              user_name: this.user_name,
              user_phone: this.user_phone,
            },
          })
          .then((result) => {
            if (result && result.msg) {
              this.err = this.user_name = this.user_phone = "";
              this.err = result.msg;
              setTimeout(() => {
                this.err = "";
              }, 3000);
            }
          });
      } else {
        this.err = "Заполните все поля";
      }
    },
  },
};
</script>

<style>
.header__content {
  background: url("/img/photo.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}
.header__content:before {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  content: "";
}
.header__wrapper {
  width: 90%;
  margin: 0 auto;
  display: flex;
  height: 80vh;
  justify-content: space-between;
  color: #fff;
}
.header__item {
  position: relative;
  display: block;
  flex-basis: 50%;
  margin-top: 15%;
}
.header__item img {
  width: 80%;
  margin-right: 5px;
}
.info {
  text-align: center;
}
.info h2 {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}
.info h2 img {
  width: 30px;
}
.info h4 {
  margin-top: 20px;
}
.phone {
  text-align: left;
}
.phone h1 {
  font-size: 35px;
}
.btn__call {
  margin-top: 10px;
  color: #fff;
  background: green;
  padding: 10px;
  border: 0;
  font-size: 20px;
  border-radius: 10px;
  box-shadow: 0 0 22px black;
  cursor: pointer;
  transition: color 0.3s;
}
.btn__call:hover {
  color: #e2e2e2;
  transform: scale(0.98);
}
.callback {
  background: #fff;
  padding: 30px;
  color: #000;
  width: 60%;
  height: max-content;
}
.callback form {
}
.callback .form__header {
  margin-bottom: 20px;
}
.callback .form__header h4 {
  font-size: 20px;
  margin-bottom: 0;
}
.callback .form__header p {
  font-size: 14px;
  margin-top: 5px;
  color: #878787;
}

.callback .form__group {
  margin-top: 10px;
}

.callback .form__group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  outline: none;
  transition: 0.5s;
}
.callback .form__group input:focus {
  border: 1px solid #008000;
}
.callback button {
  margin-top: 15px;
  display: block;
  width: 100%;
  padding: 10px;
  border: 0;
  outline: 0;
  color: #fff;
  background: #008000;
  cursor: pointer;
  transition: 0.5s;
}
.callback button:hover {
  background: #23c106;
}
.header__item__form {
  display: flex;
  justify-content: center;
}
</style>
