<template>
  <div class="about__wrapper container">
    <h2 class="title">О компании</h2>
    <div class="about__text">
      <p>
        Лесопромышленная компания "Брус-Плюс" начала свою деятельность в 1996 году. За это
        время компания вышла на одно из первых мест по производству и продажам
        лесопромышленной продукции.
      </p>
      <p>
        Предприятие специализируется на производстве изделий из лиственницы, сосны, кедра.
      </p>
    </div>
    <div class="abou__img">
      <div class="about__img__item" v-for="item in img_list" :key="item.id">
        <img :src="require('@/assets/about/' + item.img)" />
      </div>
    </div>
    <div class="about__text">
      <p>
        Благодаря приобретению нового и модернизации существующего деревообрабатывающего
        оборудования, список производимых изделий постоянно расширяется.
      </p>
      <p>
        Мы имеем небольшой парк авто и спецтехники, позволяющий оперативно решать вопросы
        <router-link to="/delivery">доставки</router-link>.
      </p>
      <p>
        Отличное качество и
        <router-link to="/price">низкие цены</router-link> дает возможность нашим
        покупателям по достоинству оценить все преимущества нашей продукции, и позволило
        нам добиться репутации надежного партнера для многих крупных предприятий.
      </p>
    </div>
    <div class="about__req"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      img_list: [
        { id: 1, img: "44d3469d-f586-425b-a61b-fc2e6bbd8091.jpg" },
        { id: 2, img: "91aca4b1-74b2-4a45-bb3b-81360bc0e75f.jpg" },
        { id: 3, img: "720cece4-e250-4984-b544-ba833a3384ac.jpg" },
        { id: 4, img: "7806ff1e-8bf1-4cac-8767-9d26fb67b937.jpg" },
        { id: 5, img: "f3777826-e515-4970-bec9-0a032f0f0399.jpg" },
        { id: 6, img: "fa571486-14a9-4f24-8e9c-31dc08e3f3f7.jpg" },
        { id: 7, img: "3a6cf9fa-146d-4780-b4c0-ca2521bf05e3.jpg" },
        { id: 8, img: "8c228ca6-6ff3-4e1a-ab1e-de7af6b21548.jpg" },
        { id: 9, img: "606b9f7b-f5b3-4591-8e12-486efed88afe.jpg" },
      ],
    };
  },
};
</script>

<style>
.about__text {
  margin-bottom: 10px;
}
.abou__img {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.about__img__item {
  flex-basis: 30%;
  height: 200px;
  display: flex;
  margin-bottom: 20px;
}
.about__img__item img {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;
  object-position: center;
}
.about__req {
  margin-top: 30px;
  border-top: 1px solid #e2e2e2;
  padding-top: 20px;
}
.title {
  margin-top: 30px;
  color: green;
  border-bottom: 2px solid green;
  margin-bottom: 10px;
}
.about__wrapper {
  margin-top: 100px;
}
</style>
