<template>
  <div id="app">
    <Modal />
    <Menu />
    <div class="body__wrapper">
      <router-view></router-view>
    </div>
    <FormCall />
    <Footer />
  </div>
</template>

<script>
import Menu from "@/components/v-menu.vue";
import FormCall from "@/components/v-form-call.vue";
import Footer from "@/components/v-footer.vue";
import Modal from "@/components/v-modal.vue";

export default {
  name: "App",
  components: {
    Menu,
    FormCall,
    Footer,
    Modal,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import "css/style.css";
@import "css/media.css";
.container {
  width: 90%;
  margin: 0 auto;
}
.center__bar {
  flex-basis: 60%;
  margin-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
}
p {
  margin: 15px 0;
}
.bold {
  font-weight: 700;
}
a {
  color: #5f4330;
}
</style>
